import styled from "@emotion/styled";
export const StyledCarouselTagsComponent = styled.div`
  .carousel {
    .slick-slider .slick-list .slick-track {
      padding-top: 0;
      display: flex;

      .slick-slide {
        width: auto !important;
      }
    }

    &.slick-active {
      .carousel-arrow-wrapper {
        border-color: transparent;
      }
    }
  }
`;
